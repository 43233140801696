@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');

html,
body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
    text-align: center;
    font-weight: bold;
    margin-bottom: 24px;
    color: #2483C3;
}

.input-box {
    background-color: #ffffff;
    border-radius: 8px;
    padding: 5px 10px;
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.08);
    font-size: 14px;
    color: black;
}

.input-box:focus {
    outline: none;
    box-shadow: 0 0 0 2px #4db6ac;
}

.linenumber {
    display: inline !important;
}

hr {
    border: 1px solid #d1d5db;
    opacity: 0.5;
    margin: 2em;
}

p {
    font-size: 17px;
    line-height: 22px;
    margin: 0;
}

img {
    user-drag: none;
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.rsw-editor {
    border-left-width: 2px !important;
    border-top-width: 0px;
    border-bottom-width: 0px;
    border-right-width: 0px;
    border-radius: 0px !important;
}

.rsw-toolbar {
    display: none !important;
}

.rsw-ce {
    outline: none !important;
    padding: 0 !important;
}